@use '@angular/material' as mat;

@tailwind base;
@tailwind components;
@tailwind utilities;

@include mat.core();

$mat-primary: mat.define-palette(mat.$indigo-palette, 500);
$mat-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$mat-theme: mat.define-light-theme((color: (primary: $mat-primary,
        accent: $mat-accent,
      ),
      typography: mat.define-typography-config(),
      density: 0,
    ));

@include mat.all-component-themes($mat-theme);

.amplify-button--primary {
  background-color: var(--amplify-components-button-primary-background-color);
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

mat-form-field {
  @apply w-full;
}

.mdc-notched-outline__notch {
  border-right: none !important;
}

.detail-row {
  @apply px-4;
  @apply py-4;
  @apply sm:grid;
  @apply sm:gap-4;
  @apply sm:px-0;
  @apply sm:grid-cols-3;
  @apply md:grid-cols-4;
  @apply lg:grid-cols-5;
}

.detail-row dt {
  @apply text-sm;
  @apply font-medium;
  @apply leading-6;
  @apply text-gray-900;
}

.detail-row dd {
  @apply mt-1;
  @apply text-sm;
  @apply leading-6;
  @apply text-gray-700;
  @apply sm:mt-0;
  @apply sm:col-span-2;
  @apply md:col-span-3;
  @apply lg:col-span-4;
}

a.text-link {
  @apply text-blue-500;
  @apply transition;
  @apply duration-150;
  @apply ease-in-out;
  @apply hover:text-blue-600;
  @apply focus:text-blue-600;
  @apply active:text-blue-700;
  @apply dark:text-blue-400;
  @apply dark:hover:text-blue-500;
  @apply dark:focus:text-blue-500;
  @apply dark:active:text-blue-600;
}


.badge
{
  @apply text-xs;
  @apply font-medium;
  @apply px-2.5;
  @apply py-0.5;
  @apply rounded;
  @apply border;
}

.badge.badge-default {
  @apply bg-blue-100;
  @apply text-blue-800;
  @apply border-blue-400;
}

.badge.badge-green {
  @apply bg-green-100;
  @apply text-green-800;
  @apply border-green-400;
}

.badge.badge-yellow {
  @apply bg-yellow-100;
  @apply text-yellow-800;
  @apply border-yellow-400;
}

.badge.badge-red {
  @apply bg-red-100;
  @apply text-red-800;
  @apply border-red-400;
}

.alert
{
  @apply border;
  @apply px-4;
  @apply py-3;
  @apply rounded;
  @apply relative;
}

.alert-red
{
  @apply bg-red-100;
  @apply border-red-400;
  @apply text-red-700;
}
